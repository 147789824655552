.hero {
  padding: 14rem 0 10rem;
  position: relative;
  text-align: center;
  @media #{$laptop} {
    padding: 20rem 0 10rem;
  }
  &:before {
    background: $white;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  &-bg {
    background-color: $white;
    background-position: center;
    background-size: 70%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    @media #{$laptop} {
      background-position: bottom center;
      background-size: 80.2rem;
      height: 52rem;
    }
    &:after {
      background: $white;
      content: "";
      display: block;
      height: 100%;
      opacity: 0.3;
      position: absolute;
      width: 100%;
      @media #{$laptop} {
        opacity: 0;
      }
    }
  }
  &-title {
    @extend %hero-title-text;
    line-height: 1.2;
    opacity: 0;
    padding: 0 2rem;
    position: relative;
    transform: translate(0,5rem);
    transition: 0.8s ease-out 0.8s;
    z-index: 3;
    @media #{$laptop} {
      line-height: 1;
    }
    .loaded & {
      opacity: 1;
      transform: translate(0,0);
    }
  }
  &-divider {
    @extend %divider-svg;
    display: none;
    position: relative;
    z-index: 3;
    @media #{$laptop} {
      display: block;
      height: 13rem;
      margin: 1rem 0 2.5rem;
    }
    i {
      display: block;
      height: 0;
      transition: 0.6s ease-out 1.6s;
      width: 100%;
    }
    .loaded & i {
      height: 100%;
    }
  }
  &-highlights {
    display: none;
    position: relative;
    z-index: 3;
    @media #{$laptop} {
      display: block;
      font-size: 0;
      margin: 0 auto;
      max-width: 120rem;
      opacity: 0;
      transition: 0.6s ease-out 2.2s;
      width: 100%;
      .loaded & {
        opacity: 1;
      }
    }
    &-item {
      display: inline-block;
      padding: 0 4rem;
      vertical-align: top;
      width: 33.33%;
      &-icon {
        margin-bottom: 1.5rem;
        svg {
          display: block;
          height: 5rem;
          margin: 0 auto;
          width: 5rem;
          @media #{$laptop} {
            height: 8rem;
            width: 8rem;
          }
          &.stars-icon {
            stroke-dasharray: 71;
            stroke-dashoffset: 71;
          }
          &.clamp-icon {
            stroke-dasharray: 140;
            stroke-dashoffset: 140;
          }
          &.puzzle-icon {
            stroke-dasharray: 164;
            stroke-dashoffset: 164;
          }
          .bg {
            opacity: 0;
            stroke-dashoffset: 0;
          }
          .loaded & {
            stroke-dashoffset: 0;
            transition: 1.2s ease 2s;
            & .bg {
              opacity: 0.2;
              transition: 1s ease-out 2.8s;
            }
          }
        }
      }
      &-title {
        @extend %text-wide;
        margin-bottom: 1rem;
        @media #{$desktop} {
          margin-bottom: 1.5rem;
        }
      }
      &-text {
        @extend %body-copy;
      }
    }
  }
  &-cta {
    margin-top: 2.5rem;
    opacity: 0;
    position: relative;
    text-align: center;
    transition: 0.6s ease-out 1.4s;
    z-index: 3;
    @media #{$laptop} {
      margin-top: 10rem;
    }
    @media #{$laptop} {
      transition-delay: 2.8s;
    }
    .loaded & {
      opacity: 1;
    }
  }
}