.header {
  & {
    background: #fff;
    height: 6rem;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    @media #{$laptop} {
      height: 7rem;
    }
    @media #{$desktop} {
      height: 8rem;
    }
  }
  & .menu-btn {
    height: 2rem;
    left: 2.5rem;
    margin-top: 0.2rem;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    width: 2.5rem;
    z-index: 3;
    span {
      background: $green;
      display: block;
      height: 0.2rem;
      left: 50%;
      margin-bottom: 0.6rem;
      position: absolute;
      top: 50%;
      width: 2.5rem;
      &:nth-child(1) {
        transform: translate(-50%,-0.6rem);
      }
      &:nth-child(2) {
        transform: translate(-50%,0);
      }
      &:nth-child(3) {
        transform: translate(-50%,0.6rem);
      }
    }
    @media #{$laptop} {
      display: none;
    }
    .menu-active & {
      span {
        background: $green;
        display: block;
        height: 0.2rem;
        left: 50%;
        margin-bottom: 0.6rem;
        position: absolute;
        top: 50%;
        width: 2.5rem;
        &:nth-child(1) {
          transform: translate(-50%,0) rotate(-45deg);
        }
        &:nth-child(2) {
          width: 0;
        }
        &:nth-child(3) {
          transform: translate(-50%,0) rotate(45deg);
        }
      }
    }
  }
  &-social {
    display: none;
    @media #{$laptop} {
      display: block;
      left: 2.5rem;
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      z-index: 3;
    }
    @media #{$mac} {
      left: 3.5rem;
    }
    &-link {
      margin-right: 2.5rem;
      &:last-child {
        margin-right: 0;
      }
      .no-touchevents & .icon svg {
        transition: 0.3s ease-out;
      }
      .no-touchevents &:hover .icon svg {
        fill: $green;
      }
      @media #{$mac} {
        margin-right: 3.5rem;
      }
    }
    .icon {
      &-text {
        display: none;
      }
      svg {
        fill: $color--secondary;
        height: 2rem;
        width: 2rem;
      }
    }
  }
  & .logo {
    color: $color--secondary;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    @media #{$laptop} {
      margin-top: -0.4rem;
    }
    svg {
      fill: $blue;
      width: 13rem;
      height: 4rem;
      @media #{$desktop} {
        width: 16rem;
        height: 4.6rem;
      }
      @media #{$mac} {
        width: 18rem;
        height: 5.2rem;
      }
    }
  }
  & .nav {
    background: #fff;
    height: 100%;
    overflow: auto;
    padding: 8rem 2.5rem 2.5rem;
    position: absolute;
    width: 100%;
    @media #{$laptop} {
      background: none;
      height: auto;
      overflow: visible;
      padding: 0;
    }
    &-ctn {
      -webkit-overflow-scrolling: touch;
      height: 0;
      left: 0;
      opacity: 0;
      overflow: hidden;
      position: fixed;
      top: 0;
      transition: opacity 0.45s ease-out, visibility 0.45s ease-out;
      visibility: hidden;
      width: 100%;
      z-index: 1;
      @media #{$laptop} {
        height: auto;
        left: 50%;
        opacity: 1;
        overflow: visible;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%,-50%);
        visibility: visible;
        z-index: 1;
      }
      .menu-active & {
        height: 100%;
        opacity: 1;
        overflow: auto;
        visibility: visible;
      }
    }
    &-left,
    &-right {
      @media #{$laptop} {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    &-left {
      @media #{$laptop} {
        right: 50%;
        padding: 0 7%;
        white-space: nowrap;
        text-align: right;
        width: 50%;
      }
    }
    &-right {
      @media #{$laptop} {
        left: 50%;
        padding: 0 16rem 0 7%;
        white-space: nowrap;
        text-align: left;
        width: 50%;
      }
    }
    &-link {
      @extend %nav-link-text;
      color: $color--secondary;
      display: block;
      padding-left: 0.3rem;
      @media #{$laptop} {
        display: inline-block;
        padding: 0.5rem;
      }
      .no-touchevents & {
        transition: 0.3s ease-out;
      }
      .no-touchevents &:hover {
        color: $green;
      }
    }
    &-left .nav-link,
    &-right .nav-link {
      margin-bottom: 4rem;
    }
    &-left .nav-link {
      @media #{$laptop} {
        margin: 0 8%;
      }
      @media #{$mac} {
        margin: 0 10%;
      }
    }
    &-right .nav-link {
      @media #{$laptop} {
        margin: 0 5%;
      }
      @media #{$mac} {
        margin: 0 6%;
      }
    }
    &-btn {
      background: $color--secondary;
      border-radius: 0.3rem;
      color: #ffffff;
      display: inline-block;
      padding: 2rem;
      text-transform: uppercase;
      @media #{$laptop} {
        background: rgba($green,0.7);
        border: 0.2rem solid rgba($green,0);
        color: $color--secondary;
        padding: 1rem;
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translate(0,-50%);
      }
      @media #{$mac} {
        padding: 1.5rem 2rem;
      }
      @extend %nav-link-text;
    }
    .no-touchevents &-btn {
      transition: 0.3s ease-out;
    }
    .no-touchevents &-btn:hover {
      @media #{$laptop} {
        background: $color--secondary;
        color: #fff;
      }
    }
  }
}