// ==========================================================================
// Global Configs
// ==========================================================================

// Text
  $base__font-size : 62.5%;

// Font Weights
  $font-weight--thin       : 100;
  $font-weight--light      : 200;
  $font-weight--book       : 300;
  $font-weight--normal     : 400;
  $font-weight--semibold   : 600;
  $font-weight--bold       : 700;
  $font-weight--heavy      : 800;
  $font-weight--ultra-bold : 900;

// Font Stacks
  $font-family--primary   : "mr-eaves-sans", sans-serif;
  $font-family--secondary : "niveau-grotesk", sans-serif;

// Descriptive Base Colors
  $white : #f5f4f4;
  $black : #222222;
  $gray  : #666666;
  $blue  : #314e9b;
  $green : #8cd2d1;

  $color--primary   : $gray;
  $color--secondary : $blue;
  $color--input-bg  : #ebe9e5;


// Responsive Sizes
  $mobile-only : "(max-width: 767px)";
  $tablet      : "(min-width: 768px)";
  $laptop      : "(min-width: 1024px)";
  $desktop     : "(min-width: 1200px)";
  $mac         : "(min-width: 1400px)";
// @media #{$mobile-only} {
//   // code here
// }
