.about {
  background-color: $white;
}
.steps {
  background: #fff;
  opacity: 0;
  overflow: hidden;
  padding: 6rem 0 2rem;
  transition: 0.8s ease-out;
  &.active {
    opacity: 1;
  }
  @media #{$laptop} {
    background: $white;
    padding: 6rem 0 6rem;
  }
  &-title {
    @extend %section-title-text;
    text-align: center;
  }
  &-text {
    @extend %steps-intro-text;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  &-ctn {
    max-width: 50rem;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
    @media #{$laptop} {
      max-width: none;
      padding: 6rem 2rem 2rem;
    }
  }
  &-image {
    display: none;
    @media #{$laptop} {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      overflow: hidden;
    }
    img {
      height: 90%;
      position: absolute;
      top: 5%;
      right: 8rem;
      width: auto;
    }
  }
  &-item {
    max-width: 50rem;
    padding: 1rem 0;
    position: relative;
    @media #{$laptop} {
      max-width: 72rem;
      margin-left: 50%;
      text-align: left;
    }
    &-icon {
      display: block;
      height: 5rem;
      left: 0;
      margin: 0 auto 1.5rem;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 5rem;
      @media #{$laptop} {
        height: 8rem;
        left: -4rem;
        width: 8rem;
      }
    }
    &-content {
      padding-bottom: 2rem;
      position: relative;
      @media #{$laptop} {
        padding-bottom: 4rem;
      }
    }
    &-title {
      &-ctn {
        margin-bottom: 1rem;
        padding: 0 2rem 0 7rem;
        position: relative;
        @media #{$laptop} {
          margin-bottom: 1rem;
          padding: 0 8rem;
        }
      }
      & {
        @extend %steps-item-title-text;
        line-height: 1.4;
      }
    }
    &-text {
      &-ctn {
        padding: 0 2rem 0 7rem;
        position: relative;
        min-height: 5rem;
        @media #{$laptop} {
          min-height: 8rem;
          padding: 0 8rem;
        }
      }
      & {
        @extend %body-copy;
      }
    }
    &-content & {
      &-title,
      &-text {
        opacity: 0;
        transition: 0.6s ease-out;
      }
    }
    &.active &-content & {
      &-title,
      &-text {
        transition: 1s ease-out;
        transform: translate(5rem, 0);
      }
    }
    &.active &-content & {
      &-title,
      &-text {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
    &-divider {
      @extend %divider-svg;
      height: 0;
      left: 2.5rem;
      margin-left: -0.1rem;
      position: absolute;
      top: 1.5rem;
      transition: 0.6s ease-out;
      width: 0.2rem;
      @media #{$laptop} {
        left: 0;
        margin-left: -0.2rem;
        top: 2.5rem;
      }
    }
    &-icon {
      &.camera-icon {
        stroke-dasharray: 117;
        stroke-dashoffset: 117;
      }
      &.calendar-icon {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
      }
      &.retainers-icon {
        stroke-dasharray: 59;
        stroke-dashoffset: 59;
      }
      transition: 0.6s ease-out;
      .bg {
        opacity: 0;
        stroke-dashoffset: 0;
        transition: 0.6s ease-out 0s;
      }
    }
    &.active &-icon {
      stroke-dashoffset: 0;
      transition: 1s ease-in-out;
      .bg {
        opacity: 0.2;
        stroke-dashoffset: 0;
        transition-delay: 0.6s;
      }
    }
    &.active & {
      &-divider {
        height: 100%;
        transition: 0.8s ease-out;
      }
    }
    &:last-child &-divider {
      display: none;
    }
  }
  &-cta {
    display: none;
    @media #{$laptop} {
      display: block;
      position: relative;
      text-align: center;
      z-index: 1;
    }
  }
}

.rules {
  background: #fff;
  padding: 4rem 0;
  @media #{$laptop} {
    background: $white;
    padding: 12rem 4rem 8rem;
    &:before {
      background: linear-gradient($white, $green 10%);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }
  &-content {
    position: relative;
    text-align: center;
    z-index: 1;
    @media #{$laptop} {
      background: $white;
      margin: 0 auto;
      max-width: 98rem;
      padding: 3rem 0 0;
    }
    @media #{$mac} {
      padding: 4rem 0 0;
    }
  }
  &-intro {
    background: $white;
    padding: 1rem 2rem 4rem;
    transition: 0.6s ease-out;
    @media #{$laptop} {
      padding: 0 8rem 6rem;
    }
  }
  &-icon {
    display: block;
    height: 8rem;
    margin: 0 auto;
    stroke-dasharray: 90;
    stroke-dashoffset: 90;
    transition: 0.6s ease-out;
    width: 8rem;
    @media #{$laptop} {
      height: 10rem;
      width: 10rem;
    }
    .bg {
      opacity: 0;
      transition: 0.6s ease-out;
    }
  }
  &-title {
    @extend %text-wide;
    opacity: 0;
    text-align: center;
    transition: 0.6s ease-out;
    @media #{$laptop} {
      padding: 0 0 2rem;
    }
  }
  &-text {
    @extend %section-title-text;
    color: $color--secondary;
    opacity: 0;
    text-align: center;
    transition: 0.6s ease-out;
    @media #{$laptop} {
      padding: 0 0 2rem;
    }
  }
  &-intro.active & {
    &-icon {
      stroke-dashoffset: 0;
      transition: 0.8s ease-in-out;
      .bg {
        opacity: 0.2;
        transition: 1s ease-out 0.2s;
      }
    }
    &-title,
    &-text {
      opacity: 1;
      transition: 1s ease-out 0.4s;
    }
  }
  &-pretext,
  &-posttext {
    background: #bde3e3;
    border-radius: 0.3rem;
    color: $color--secondary;
    display: block;
    font-size: 1.6rem;
    font-weight: $font-weight--bold;
    margin: 0 auto;
    padding: 1.5rem;
    text-align: center;
    width: 24rem;
  }
  &-pretext {
    border-radius: 0.3rem;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    transform: translate(-50%,-50%);
    @media #{$laptop} {
      font-size: 2.4rem;
      padding: 2rem 1rem;
      width: 40rem;
    }
  }
  &-posttext {
    @media #{$laptop} {
      border-radius: 0;
      font-size: 3.2rem;
      padding: 5rem 2rem;
      width: 100%;
    }
  }
  &-posttext span {
    display: inline-block;
  }
  .no-touchevents &-posttext {
    @media #{$laptop} {
      transition: 0.45s ease-out;
    }
  }
  .no-touchevents &-posttext.active,
  .no-touchevents &-posttext:hover {
    @media #{$laptop} {
      background-color: $color--secondary;
      color: #fff;
      transition: 0.45s ease-out 0s;
    }
  }
  &-list {
    &-ctn {
      background: #fff;
      padding: 3.5rem 2rem 2.5rem;
      @media #{$laptop} {
        padding: 8rem 8rem;
      }
    }
    font-size: 0;
    max-width: 32rem;
    margin: 0 auto;
    @media #{$laptop} {
      max-width: 54rem;
    }
    @media #{$mac} {
      max-width: 60rem;
    }
    &-item {
      @extend %body-copy-md;
      color: $color--secondary;
      display: inline-block;
      font-weight: $font-weight--normal;
      opacity: 0;
      padding: 1rem 0.5rem;
      text-align: left;
      transition: 0.6s ease-out;
      transition-delay: 0;
      vertical-align: top;
      width: 50%;
      @media #{$laptop} {
        padding: 2rem 3rem;
      }
      a {
        display: block;
        padding: 0 0 0 2.5rem;
        position: relative;
        @media #{$laptop} {
          padding: 0 0 0  3.5rem;
        }
        svg {
          display: block;
          fill: none;
          height: 2.4rem;
          left: 0;
          margin: 0.25rem 0;
          position: absolute;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke: #8CD2D1;
          top: 0;
          vertical-align: middle;
          width: 2rem;
          @media #{$laptop} {
            width: 3rem;
          }
        }
        span {
          display: block;
          line-height: 1.2;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: keep-all;
        }
      }
    }
    &.active &-item {
      opacity: 1;
      &:nth-child(1) {transition-delay: 0.15s;}
      &:nth-child(2) {transition-delay: 0.3s;}
      &:nth-child(3) {transition-delay: 0.45s;}
      &:nth-child(4) {transition-delay: 0.6s;}
      &:nth-child(5) {transition-delay: 0.75s;}
      &:nth-child(6) {transition-delay: 0.9s;}
    }
  }
}

.facts {
  display: none;
  position: relative;
  @media #{$laptop} {
    display: block;
    padding-top: 5rem;
  }
  &-title {
    @extend %section-title-alt;
    position: absolute;
    width: 100%;
    &:before {
      background: $green;
    }
  }
  &-ctn {
    font-size: 0;
  }
  &-item {
    display: inline-block;
    padding: 12rem 4rem;
    vertical-align: middle;
    width: 50%;
    @media #{$desktop} {
      padding: 18rem 4rem;
    }
    &-content {
      margin: 0 auto;
      max-width: 45rem;
      text-align: center;
    }
    &-heading {
      @extend %body-copy-lg;
      color: $color--secondary;
      margin-bottom: 1.5rem;
    }
    &-copy {
      @extend %body-copy;
    }
  }
  &-image {
    background-position: center;
    background-size: cover;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }
  &:before {
    background: linear-gradient(90deg, #f5f4f4 49.5%, #fff 50.5%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}

.quote {
  background-color: $green;
  background-size: cover;
  padding: 4rem;
  @media #{$laptop} {
    padding: 10rem 4rem;
  }
  @media #{$mac} {
    padding: 15rem 4rem 10rem;
  }
  &-ctn {
    background: $blue;
    color: #fff;
    margin: 0 auto;
    max-width: 32rem;
    padding: 4rem 4rem;
    position: relative;
    text-align: center;
    @media #{$laptop} {
      max-width: 68rem;
      padding: 6rem 10rem;
    }
    @media #{$mac} {
      max-width: 82rem;
      padding: 8rem 15rem;
    }
  }
  &-content {
    opacity: 0;
    transition: 0.6s ease-out;
  }
  &-ctn.active  &-content{
    opacity: 1;
  }
  &-text {
    @extend %quote-text;
    line-height: 1.2;
    position: relative;
    svg {
      width: 1em;
      height: 1em;
      &.left,
      &.right {
        position: absolute;
        top: 0;
      }
      &.left {
        right: 100%;
      }
      &.right {
        left: 100%;
        transform: rotate(180deg);
      }
    }
  }
  &-icon {
    height: 5rem;
    margin: 1.5rem auto;
    width: 5rem;
    @media #{$laptop} {
      height: 8rem;
      margin: 2.5rem auto 2.5rem;;
      width: 8rem;
    }
    svg {
      height: 5rem;
      stroke-dasharray: 101;
      stroke-dashoffset: 101;
      transition: 0.6s ease-out;
      width: 5rem;
      .bg {
        opacity: 0;
        stroke-dashoffset: 0;
        transition: 0.6s ease-out 0s;
      }
      @media #{$laptop} {
        height: 8rem;
        width: 8rem;
      }
    }
  }
  &-ctn.active &-icon svg {
    stroke-dashoffset: 0;
    transition: 0.8s linear 0.6s;
    .bg {
      opacity: 0.2;
      transition: 0.45s ease-out 1.2s;
    }
  }
  &-author {
    @extend %text-bold;
    margin-bottom: 1rem;
    @media #{$mac} {
      margin-bottom: 1.5rem;
    }
    &:before {
      content: "-";
      margin-right: 0.3rem;
    }
    &-title {
      @extend %text-wide;
      color: $green;
      letter-spacing: 0.2em;
    }
  }
}