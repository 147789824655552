.plans {
  overflow: hidden;
  @media #{$tablet} {
    padding-bottom: 3rem;
  }
  &:before {
    background: linear-gradient(rgba($green,0.4), #fff);
    content: "";
    display: block;
    height: 6rem;
    margin-bottom: 1rem;
    width: 100%;
    @media #{$laptop} {
      height: 12rem;
    }
    @media #{$mac} {
      height: 15rem;
      margin-bottom: 2rem;
    }
  }
  &-title {
    @extend %section-title-text;
    text-align: center;
  }
  &-highlights {
    display: none;
    @media #{$laptop} {
      display: block;
      font-size: 0;
      margin: 4rem auto 6rem;
      max-width: 120rem;
    }
    &-item {
      display: inline-block;
      padding: 2rem;
      text-align: center;
      vertical-align: top;
      width: 50%;
      &-content {
        margin: 0 auto;
        max-width: 45rem;
        position: relative;
        &:before {
          background: $green;
          content: "";
          display: block;
          height: 0.2rem;
          margin: 0 auto;
          max-width: 25rem;
          @media #{$mac} {
            max-width: 30rem;
          }
        }
      }
      &-heading {
        @extend %highlight-heading-text;
        color: $color--secondary;
        padding: 3rem 0 1rem;
        text-transform: uppercase;
      }
      &-copy {
        @extend %highlight-copy-text;
      }
    }
  }
  &-ctn {
    font-size: 0;
    margin: 3rem auto 0;
    max-width: 120rem;
    outline: none;
    @media #{$laptop} {
      margin: 0 auto;
      padding: 0 1rem;
    }
  }
  &-ctn &-item {
    @media #{$tablet} {
      opacity: 0;
      transform: translate(0, 5rem);
      &:first-child {
        transition: 0.6s ease-out 0s;
      }
      &:nth-child(2) {
        transition: 0.6s ease-out 0s;
      }
      &:last-child {
        margin-right: 0;
        transition: 0.6s ease-out 0s;
      }
    }
  }
  &-item {
    margin: 0 auto;
    max-width: 40rem;
    padding: 0 0.5rem;
    width: 75%;
    .flickity-enabled & {
      transform: scale(0.9);
      transition: 0.2s ease-out;
      &.is-selected {
        transform: scale(1);
      }
    }
    @media #{$tablet} {
      display: inline-block;
      padding: 0 1rem;
      width: 33.33%;
    }
    &-content {
      box-shadow: 0 0.5rem 2rem #ccc;
      color: $color--secondary;
      position: relative;
      text-align: center;
      transition: 0.6s ease-out;
      @media #{$laptop} {
        border-radius: 1rem;
      }
      &:after {
        content: "";
        background: $blue;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        .flickity-enabled & {
          opacity: 0.2;
          transition: 0.2s ease-out;
        }
        .flickity-enabled .is-selected & {
          opacity: 0;
        }
      }
    }
    &-title {
      @extend %plan-title-text;
      background: #d6dceb;
      padding: 1.5rem;
      transition: 0.6s ease-out;
      @media #{$laptop} {
        background: $white;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        padding: 2rem 1.5rem 1.5rem;
      }
    }
    &-details {
      border-bottom: 0.1rem solid $green;
      border-left: 0.1rem solid $green;
      border-right: 0.1rem solid $green;
      padding: 0 1.5rem;
      transition: 0.6s ease-out;
      @media #{$laptop} {
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        border-bottom: 0.2rem solid $green;
        border-left: 0.2rem solid $green;
        border-right: 0.2rem solid $green;
      }
    }
    &-price {
      @extend %body-copy-md;
      padding: 2rem;
    }
    &-description {
      font-size: 1.8rem;
      font-weight: $font-weight--bold;
      @media #{$laptop} {
        font-size: 2.2rem;
      }
      background: #d6dceb;
      padding: 1.5rem;
      transition: 0.6s ease-out;
      @media #{$laptop} {
        background: $white;
        padding: 1.5rem;
      }
    }
    &-icon {
      font-size: 0;
      margin: 2rem auto 0;
      @media #{$laptop} {
        margin: 2.5rem auto 0;
      }
      svg {
        display: inline-block;
        height: 5rem;
        margin-right: 1rem;
        width: 5rem;
        &:last-child {
          margin-right: 0;
        }
        @media #{$tablet} {
          height: 4rem;
          width: 4rem;
        }
        @media #{$laptop} {
          height: 5rem;
          width: 5rem;
        }
        .bg {
          opacity: 0.2;
        }
      }
    }
    &-fequency {
      @extend %text-normal;
      border-bottom: 0.2rem solid #f5f7f9;
      line-height: 1.2;
      padding: 2rem;
      @media #{$laptop} {
        padding: 2rem;
      }
    }
    &-cost {
      @extend %text-book;
      line-height: 1.4;
      padding: 2rem;
    }
    &-cta {
      padding: 0 1rem 4rem;
      .btn {
        width: 80%;
      }
    }
    &:nth-child(2) & {
      &-title {
        background: #bde3e3;
        @media #{$laptop} {
          background: #def1f1
        }
      }
      &-content {
        border: 0;
        box-shadow: 0 0rem 3rem transparent;
        @media #{$tablet} {
          border: 0.2rem solid $green;
          box-shadow: 0 0rem 3rem $green;
        }
        @media #{$laptop} {
          border-radius: 0.3rem;
        }
      }
      &-details {
        border: 0;
        padding: 0 1.5rem;
      }
      &-description {
        background: #bde3e3;
        @media #{$laptop} {
          background: #def1f1
        }
      }
    }
    &:nth-child(2).is-selected & {
      &-content {
        box-shadow: 0 0rem 3rem $green;
      }
    }
  }
  &-ctn.active &-item {
    @media #{$tablet} {
      opacity: 1;
      transform: translate(0, 0);
      &:first-child{
        transition-delay: 0s;
      }
      &:nth-child(2){
        transition-delay: 0.2s;
      }
      &:last-child{
        transition-delay: 0.4s;
      }
    }
  }
  .flickity-page-dots {
    @extend %pagers;
    padding: 3rem 0 4rem;
    @media #{$laptop} {
      display: none;
    }
  }
  .flickity-prev-next-button {
    display: none;
  }
}