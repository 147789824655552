.footer {
  background: $white;
  color: $color--secondary;
  padding: 5rem 2rem;
  text-align: center;
  @media #{$laptop} {
    padding: 10rem 0 3rem;
  }
  &-ctn {
    font-size: 0;
    @media #{$laptop} {
      padding: 0 0 5rem;
    }
  }
  &-item {
    @extend %footer-text;
    display: block;
    position: relative;
    @media #{$laptop} {
      display: inline-block;
      padding: 0 5rem;
      vertical-align: middle;
    }
    @media #{$mac} {
      padding: 0 7.5rem;
    }
    &:after {
      background: $green;
      content: "";
      display: block;
      height: 0.1rem;
      margin: 5rem auto;
      width: 10rem;
      @media #{$laptop} {
        height: 140%;
        margin: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0,-50%);
        width: 0.1rem;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  &-contact {
    @extend %footer-contact-text;
    &-email {
      margin-bottom: 1.5rem;
    }
    &-phone {
      margin-bottom: 1.5rem;
    }
    &-social {
      &-link {
        .icon {
          display: inline-block;
          height: 2rem;
          margin-right: 1rem;
          width: 2rem;
          svg {
            fill: $color--secondary;
            height: 2rem;
            width: 2rem;
            @media #{$laptop} {
              height: 2.5rem;
              width: 2.5rem;
            }
          }
          @media #{$laptop} {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
        &:last-child .icon {
          margin-right: 0;
        }
        .icon-text {
          display: none;
        }
      }
    }
  }
  &-address {
    @extend %footer-address-text;
    display: none;
    address {
      font-style: normal;
    }
    @media #{$laptop} {
      display: inline-block;
      line-height: 1.6;
    }
  }
  &-newsletter {
    &-text {
      @extend %footer-newsletter-text;
      margin-bottom: 1.5rem;
      @media #{$laptop} {
        text-align: left;
      }
    }
    &-form {
      position: relative;
      input[type="text"],
      button[type="submit"] {
        display: inline-block;
        outline: 0;
        padding: 2rem;
        vertical-align: top;
      }
      input[type="text"] {
        @extend %newsletter-input-text;
        background: $color--input-bg;
        border: 1px solid transparent;
        line-height: 1;
        width: 18rem;
        @media #{$laptop} {
          width: 20rem;
        }
        @media #{$desktop} {
          width: 20rem;
        }
        &::placeholder {
          color: $color--secondary;
          opacity: 1;
          transition: 0.2s ease-out;
        }
        &:focus::placeholder {
          opacity: 0.2;
        }
        &.invalid {
          border: 1px solid red;
        }
      }
      button[type="submit"] {
        @extend %newsletter-submit-text;
        background: $green;
        border: 1px solid transparent;
        display: inline-block;
        outline: 0;
        padding: 2rem 2.5rem;
      }
      .no-touchevents & button[type="submit"] {
        transition: 0.3s ease-out;
      }
      .no-touchevents & button[type="submit"]:hover {
        background: $color--secondary;
        color: #fff;
      }
    }
    &-message {
      position: absolute;
      top: 100%;
      margin-top: 1rem;
      width: 100%;
      font-size: 1.8rem;
      text-align: center;
    }
  }
  &-legal {
    @extend %legal-text;
    padding-top: 5rem;
    @media #{$laptop} {
      padding-top: 2rem;
    }
  }
}