// ==========================================================================
// Extends
// ==========================================================================

// Clearfix
  %clearfix {
    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  %section-title-alt {
    @extend %text-wide;
    padding: 1.5rem 2rem;
    position: relative;
    text-align: center;
    z-index: 1;
    @media #{$desktop} {
      padding: 3rem 2rem;
    }
    &:before {
      background: #fff;
      border-radius: 100%;
      content: "";
      display: block;
      height: 4rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 4rem;
      z-index: -1;
      @media #{$laptop} {
        height: 6rem;
        width: 6rem;
      }
      @media #{$mac} {
        height: 8rem;
        width: 8rem;
      }
    }
  }

  %divider-svg {
    svg {
      animation: dividerAnimation 6s linear infinite;
      height: 100%;
      stroke-dasharray: 2,5;
      stroke-width: 2;
      stroke: $green;
      width: 0.2rem;
    }
  }
  @keyframes dividerAnimation {
    0% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -100;
    }
  }

  %pagers {
    font-size: 0;
    text-align: center;
    span,
    .dot {
      background: $color--secondary;
      border-radius: 100%;
      cursor: pointer;
      display: inline-block;
      height: 0.8rem;
      margin-right: 1.5rem;
      opacity: 0.3;
      width: 0.8rem;
      &:last-child {
        margin-right: 0;
      }
      &.active,
      &.is-selected {
        opacity: 1;
      }
      @media #{$laptop} {
        height: 1rem;
        margin-right: 2rem;
        width: 1rem;
      }
    }
  }