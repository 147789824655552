.faqs {
  padding-bottom: 5rem;
  padding-top: 4rem;
  text-align: center;
  @media #{$laptop} {
    display: inline-block;
    padding-bottom: 9rem;
    padding-top: 10rem;
    width: 50%;
  }
  @media #{$mac} {
    padding-top: 15rem;
  }
  & {
    opacity: 0;
    transform: translate(0, 5rem);
    transition: 0.6s ease-out;
    .modals.active & {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  &-title {
    @extend %section-title-text;
    margin-bottom: 0.5rem;
  }
  &-text {
    @extend %body-copy-md;
    color: $color--primary;
    margin-bottom: 2rem;
    @media #{$laptop} {
      margin-bottom: 3rem;
    }
  }
  &-cta {}
  &-modal {
    -webkit-overflow-scrolling: touch;
    background: $white;
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: opacity 0.45s ease-out, visibility 0.45s ease-out;
    visibility: hidden;
    width: 100%;
    z-index: 9;
    @media #{$laptop} {
      padding: 5rem;
    }
    .faqs-active & {
      display: block;
      opacity: 1;
      overflow: scroll;
      visibility: visible;
    }
    &-bg {
      bottom: 0;
      display: block;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 0;
    }
    &-content {
      margin: 0 auto;
      padding: 6rem 5rem 7rem;
      position: relative;
      text-align: center;
      z-index: 0;
      @media #{$laptop} {
        background: #fff;
        max-width: 110rem;
        padding: 20rem 5rem 15rem;
      }
    }
    .faqs-form-close {
      cursor: pointer;
      height: 3rem;
      position: absolute;
      right: 2.5rem;
      top: 3rem;
      width: 3rem;
      @media #{$laptop} {
        height: 4rem;
        right: 4rem;
        top: 4rem;
        width: 4rem;
      }
      svg {
        height: 3rem;
        stroke-width: 2;
        stroke: $color--secondary;
        transform: rotate(45deg);
        width: 3rem;
        @media #{$laptop} {
          height: 4rem;
          width: 4rem;
        }
      }
    }
    &-title {
      @extend %section-title-text;
      color: $color--secondary;
      margin: 0 auto 1.5rem;
      @media #{$laptop} {
        margin: 0 auto 5rem;
      }
    }
    &-item {
      margin: 0 auto 3.5rem;
      @media #{$laptop} {
        margin: 0 auto 8rem;
      }
      &-question {
        @extend %body-copy-lg;
        color: $color--secondary;
        margin: 0 auto 1rem;
        max-width: 28rem;
        @media #{$laptop} {
          margin: 0 auto 3rem;
          max-width: 68rem;
        }
      }
      &-answer {
        @extend %body-copy;
        max-width: 28rem;
        margin: 0 auto;
        @media #{$laptop} {
          max-width: 54rem
        }
      }
    }
  }
}