// ==========================================================================
// Typography
// ==========================================================================
html,
body {
  font-family: $font-family--primary;
  font-size: 1.6rem;
}

.title {
  letter-spacing: -0.02em;
}

%body-copy {
  font-size: 2rem;
  font-weight: $font-weight--book;
  line-height: 1.35;
  @media #{$laptop} {
    font-size: 2.2rem
  }
}

%body-copy-lg {
  font-size: 2.4rem;
  font-weight: $font-weight--book;
  @media #{$laptop} {
    font-size: 3.6rem;
  }
}

%body-copy-md {
  font-size: 2.2rem;
  font-weight: $font-weight--book;
  line-height: 1.35;
  @media #{$laptop} {
    font-size: 2.8rem;
  }
}

%button-text {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.2em;
  @media #{$laptop} {
    font-size: 1.5rem;
  }
}

%footer-address-text {
  font-size: 2rem;
  font-weight: $font-weight--normal;
  @media #{$desktop} {
    font-size: 2.4rem;
  }
}

%footer-contact-text {
  font-size: 2rem;
  font-weight: $font-weight--normal;
  @media #{$desktop} {
    font-size: 2.4rem;
  }
}

%footer-text {
  font-size: 2rem;
  font-weight: $font-weight--normal;
  @media #{$desktop} {
    font-size: 2.4rem;
  }
}

%footer-newsletter-text {
  font-size: 2rem;
  font-weight: $font-weight--normal;
  @media #{$desktop} {
    font-size: 2.4rem;
  }
}

%hero-title-text {
  font-size: 4rem;
  font-weight: $font-weight--book;
  letter-spacing: -0.02em;
  @media #{$laptop} {
    font-size: 5.6rem;
  }
}

%highlight-copy-text {
  font-size: 2rem;
  font-weight: $font-weight--book;
}

%highlight-heading-text {
  font-size: 1.6rem;
  font-weight: $font-weight--bold;
  letter-spacing: 0.3em;
  line-height: 2.6rem;
}

%input-text {
  font-size: 1.6rem;
  font-weight: $font-weight--normal;
  line-height: 1;
  @media #{$laptop} {
    font-size: 2rem;
  }
}

%legal-text {
  font-size: 1.4rem;
  font-weight: $font-weight--bold;
  @media #{$laptop} {
    font-size: 1.6rem;
  }
}

%nav-link-text {
  font-size: 1.8rem;
  font-weight: $font-weight--normal;
  letter-spacing: 0.25em;
  line-height: 3rem;
  text-transform: uppercase;
  @media #{$laptop} {
    font-weight: $font-weight--bold;
    font-size: 1.2rem;
    line-height: 1;
  }
  @media (min-width: 1100px) {
    font-size: 1.3rem;
  }
  @media #{$desktop} {
    font-size: 1.4rem;
  }
  @media #{$mac} {
    font-size: 1.5rem;
  }
}

%newsletter-input-text {
  font-size: 1.4rem;
  font-weight: $font-weight--normal;
  line-height: 1;
  @media #{$desktop} {
    font-size: 2rem;
  }
}

%newsletter-submit-text {
  font-size: 1.2rem;
  font-weight: $font-weight--bold;
  letter-spacing: 0.2em;
  line-height: 1.4rem;
  text-transform: uppercase;
  @media #{$desktop} {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

%plan-title-text {
  font-size: 2.8rem;
  font-weight: $font-weight--normal;
  @media #{$laptop} {
    font-size: 3.6rem;
  }
}

%quote-text {
  font-size: 2.4rem;
  font-weight: $font-weight--book;
  @media #{$laptop} {
    font-size: 2.8rem;
  }
}

%review-author-text {
  font-size: 2.2rem;
  font-weight: $font-weight--normal;
  @media #{$laptop} {
    font-size: 3rem;
  }
}

%review-quote-text {
  @extend %body-copy-md;
  @media #{$laptop} {
    font-size: 2.6rem;
  }
}

%section-title-text {
  font-size: 3.6rem;
  font-weight: $font-weight--book;
  letter-spacing: -0.02em;
  @media #{$laptop} {
    font-size: 5.6rem;
  }
}

%steps-intro-text {
  font-size: 2.2rem;
  font-weight: $font-weight--book;
  @media #{$laptop} {
    font-size: 3rem;
  }
}

%steps-item-title-text {
  font-size: 1.4rem;
  font-weight: $font-weight--bold;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  @media #{$laptop} {
    font-size: 1.6rem;
  }
}

%text {
  font-size: 1.6rem;
  @media #{$laptop} {
    font-size: 2.2rem;
  }
}

%text-book {
  @extend %text;
  font-weight: $font-weight--book;
}

%text-normal {
  @extend %text;
  font-weight: $font-weight--normal;
}

%text-bold {
  @extend %text;
  font-weight: $font-weight--bold;
}

%text-wide {
  font-size: 1.2rem;
  font-weight: $font-weight--bold;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  @media #{$laptop} {
    font-size: 1.6rem;
  }
}