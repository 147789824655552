// ==========================================================================
// Base Styles
// ==========================================================================
html,
body {
  color: $color--primary;
  display: block;
  height: auto;
  margin: 0 auto;
  overflow: auto;
  padding: 0;
  width: 100%;
}
html {
  &.menu-active,
  &.faqs-active,
  &.orthodontists-active {
    overflow: hidden;
    // position: fixed;
  }
}
body {
  position: relative;
  // .menu-active &,
  // .faqs-active &,
  // .orthodontists-active & {
  //   overflow: hidden;
  //   // position: fixed;
  // }
}
a {
  color: inherit;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}
button {
  background: inherit;
  border: 0;
  cursor: pointer;
  outline: 0;
}
input,
textarea {
  -webkit-appearance: none;
  background: inherit;
  border-radius: 0;
  border: 0;
  outline: 0;
  resize: none;
}
h1,
h2,
h3,
h4,
h5 {
  display: block;
  line-height: 1;
  margin: 0 auto;
  padding: 0;
}
p {
  display: block;
  line-height: 1.4;
  margin: 0 auto;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6{
  color: $color--secondary;
  margin: 0;
}
img {
  height: auto;
  outline: none;
  width: 100%;
}

header ul,
footer ul {
  color: white;
  list-style: none;
  margin: 0;
  padding: 0;
}

header ul a,
footer ul a {
  color: white;
  text-decoration: none;
}

footer {
  position: relative;
  width: 100%;
}
div.hidden,
span.hidden {
  display: none;
  visibility: hidden;
}

main {
  display: block;
  margin: 0 auto;
  opacity: 0;
  padding: 0;
  position: relative;
  transition: 0.8s ease-out;
  .loaded & {
    opacity: 1;
  }
}

section {
  position: relative;
}

.btn {
  display: inline-block;
  margin-top: 1rem;
  position: relative;
  z-index: 1;
  &:before {
    border-radius: 0.3rem;
    border: 0.1rem solid #8cd2d1;
    content: "";
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate(-0.5rem, -0.5rem);
    width: 100%;
    z-index: 0;
    @media #{$laptop} {
      border: 0.2rem solid #8cd2d1;
      transform: translate(-1rem, -1rem);
    }
  }

  span {
    @extend %button-text;
    background-color: $color--secondary;
    border-radius: 0.3rem;
    color: #fff;
    display: block;
    padding: 1.5rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    @media #{$laptop} {
      padding: 2rem;
    }
    @media #{$mac} {
      padding: 2.5rem;
    }
  }
  .no-touchevents & {
    span {
      transition: 0.3s ease-out;
    }
    &:before {
      transition: 0.3s ease-out;
    }
  }
  .no-touchevents &:hover {
    span {
      background-color: $green;
      color: $color--secondary;
      font-weight: 600;
      transition: 0.4s ease-out;
    }
    &:before {
      opacity: 0;
      transform: translate(0,0);
      transition: 0.6s ease-out;
      @media #{$laptop} {
        transform: translate(0,0);
      }
    }
  }

  [class*="-cta"] & {
    width: 18rem;
    @media #{$laptop} {
      width: 28rem;
    }
  }
}

[data-scroll-trigger="fade"] {
  opacity: 0;
  transition: opacity 0.8s ease-out;
  &.active {
    opacity: 1;
  }
}
[data-scroll-trigger="fade-up"] {
  opacity: 0;
  transform: translate(0, 5rem);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  &.active {
    opacity: 1;
    transform: translate(0, 0);
  }
}
[data-scroll-trigger="slide-up"] {
  transform: translate(0, 5rem);
  transition: transform 0.8s ease-out;
  &.active {
    transform: translate(0, 0);
  }
}