.contact {
  color: $color--secondary;
  padding: 5rem 0;
  text-align: center;
  @media #{$laptop} {
    padding: 9rem 0 18rem;
  }
  &-title {
    @extend %text-wide;
    margin-bottom: 1.5rem;
    @media #{$laptop} {
      margin-bottom: 2.5rem;
    }
  }
  &-text {
    @extend %section-title-text;
    margin-bottom: 2.5rem;
  }
  &-form {
    margin: 0 -0.5rem;
    &-ctn {
      font-size: 0;
      margin: 0 auto;
      max-width: 75rem;
      padding: 0 3rem;
    }
    &-item {
      display: inline-block;
      padding: 0.5rem;
      width: 50%;
      &.wide {
        width: 100%;
        &.hp {
          height: 0;
          width:0;
          margin: 0;
          padding: 0;
          border: 0;
          overflow: hidden;
        }
      }
    }
    input[type="text"],
    textarea {
      @extend %input-text;
      background: $color--input-bg;
      border: 1px solid transparent;
      display: block;
      outline: 0;
      padding: 1.5rem;
      width: 100%;
      @media #{$laptop} {
        padding: 2rem;
      }
      &::placeholder {
        color: $color--secondary;
        opacity: 1;
        transition: 0.2s ease-out;
      }
      &:focus::placeholder {
        opacity: 0.2;
      }
    }
    input[type="text"].invalid {
      border: 1px solid red;
    }
    &-checkbox {
      padding: 1.5rem 2rem 0;
      @media #{$laptop} {
        padding: 2.8rem 2rem 0.8rem;
      }
      input[type="checkbox"] {
        display: none;
      }
      label {
        @extend %input-text;
        color: $color--secondary;
        cursor: pointer;
        display: inline-block;
        &:before {
          background: $green;
          border: 0.3rem solid $green;
          content: "";
          display: inline-block;
          height: 1em;
          margin-right: 1.5rem;
          position: relative;
          top: 0.2em;
          transition: 0.2s ease-out;
          width: 1em;
        }
      }
      input[type="checkbox"]:checked {
        & ~ label {
          &:before {
            background: $blue;
            border: 0.2rem solid $green;
          }
        }
      }
    }
    .btn {
      display: inline-block;
      margin-top: 2rem;
      width: 15rem;
      @media #{$laptop} {
        margin-top: 3rem;
        width: 24rem;
      }
    }
  }
  &-response {
    font-size: 1.8rem;
    margin-top: 1rem;
  }
}